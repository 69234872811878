import {EcomComponent, withEcomPlatformData} from '@wix/ecom-platform-sdk'
import {withMembersArea, MA_APP_IDS} from '@wix/members-area-integration-kit'

import {EXPERIMENTS, PARENT_APP_DEF_ID, RESTAURANTS_PETRI_SCOPE} from '../utils/constants'

import {editorReady as editorReadyImplementation} from './editorReady'
import {getAppManifest as getAppManifestImplementation} from './getAppManifest'
import {onEvent as onEventImplementation} from './onEvent'
import {exports as exportsImplementation} from './exports'

export const platformApp = withMembersArea(
  withEcomPlatformData(
    {
      editorReady: editorReadyImplementation,
      getAppManifest: getAppManifestImplementation,
      onEvent: onEventImplementation,
      exports: exportsImplementation,
    } as any,
    async () => {
      return {
        ecomComponents: {[EcomComponent.CHECKOUT]: {isInstalled: true}},
        shouldInstallMembersArea: false,
        shouldSkipEcomInstallation: false,
        managingAppId: PARENT_APP_DEF_ID,
      }
    },
  ),
  {
    experimentOptions: {
      name: EXPERIMENTS.shouldInstallMembersArea,
      scope: RESTAURANTS_PETRI_SCOPE,
      truthyValue: true.toString(),
    },
    membersAreaApps: [MA_APP_IDS.MY_ORDERS, MA_APP_IDS.MY_ADDRESSES, MA_APP_IDS.MY_WALLET],
  },
)
